export class Modules {

    public static modulesList = [
        { value: "Members", label: "Members Module" },
        { value: "Communication", label: "Communication Module" },
        { value: "Partners", label: "Partners Module" },
        { value: "Reservations", label: "Reservation Module" },
        { value: "Equipment", label: "Equipment Module" },
        { value: "Projects", label: "Project Module" },
        { value: "Classes", label: "Classes Module" },
        { value: "Payments", label: "Payments Module" },
        { value: "Fundraising", label: "Fundraising Module" },
        { value: "Reports", label: "Reports Module" }
    ]
}