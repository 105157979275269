import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VolunteerService {

  constructor(private http: HttpClient) { }

  getAllVolInfo(page, locationId, size, search, sortby, sortorder) {
    let url = environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteers?page=${page}&limit=${size}`
    if(sortby && sortorder){
      url = url + `&sortBy=${sortby}&sortOrder=${sortorder}`
    }
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getAllVolTypes(page, locationId, size, search, sortby, sortorder) {
    let url = environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteer-types?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    if(sortby && sortorder){
      url = url + `&sortBy=${sortby}&sortOrder=${sortorder}`
    }
    return this.http.get<any>(url);
  }

  getVolTypes(locationId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteer-types`);
  }

  addVolType(locationId,payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteer-types`, payload)
  }

  updateVolType(locationId,payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteer-types/${id}`, payload)
  }

  deleteVolType(locationId,id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteer-types/${id}`);
  }

  addVolHrs(locationId,payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteers`, payload)
  }

  updateVolHours(locationId,payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteers/${id}`, payload)
  }

  deleteVolInfo(locationId,id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `volunteer-management/${locationId}/volunteers/${id}`);
  }
}
