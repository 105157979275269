import { Attribute, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DonationService } from 'src/app/services/donation.service';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { LocationService } from 'src/app/services/location.service';
import { ReCaptchaService } from 'src/app/services/re-captcha.service';
const moment = _moment
declare var grecaptcha: any;
@Component({
  selector: 'app-create-donation',
  templateUrl: './create-donation.component.html',
  styleUrls: ['./create-donation.component.scss']
})
export class CreateDonationComponent implements OnInit {


  @ViewChild('ngForm') myForm: NgForm;
  donationForm: FormGroup;
  amountS;
  @Output() demo = new EventEmitter<string>();
  infoD = true;
  paymentDone = false;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  amounts = [20, 50, 100, 200, "Other"]
  showOther = false
  paymentData
  updatedDate = moment().add(1, 'years').format('YYYY-MM-DD')
  creditCardForm: FormGroup;
  filterValue = ''
  campaigData = { page: 1, totalPages: 0, limit: 100 }
  checkForm: FormGroup;
  paymentForm: FormGroup;
  campaignslist
  membersData;
  loc_id
  paymentId: any;
  token: any;
  payer_id: any;
  donationSettingDate;
  recaptchaToken: string | null = null;
  loading = false
  currentStyles: Record<string, string> = {};
  inputStyles: Record<string, string> = {};
  buttonStyles: Record<string, string> = {};
  paymentGateway
  showPayment = false
  constructor(
    @Attribute('mat-form-field-invalid') public type: string,
    private donationService: DonationService,
    private reCaptchaService: ReCaptchaService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private locationService: LocationService,
  ) { }


  ngOnInit(): void {
    this.loading = true
    this.route
      .queryParams
      .subscribe(params => {
        this.loc_id = params.loc_id
        this.paymentId = params.paymentId
        this.token = params.token
        this.payer_id = params.PayerID
        // console.log(this.loc_id)
        if (this.payer_id && this.paymentId) {
          let payload = {
            paymentId: this.paymentId
            , token: this.token
            , payer_id: this.payer_id
            , locId: this.loc_id
          }
          // console.log('Calling from init')
          this.paymentService.captureDonation(payload).subscribe(res => {
            if (res.statusCode === 200) {
              this.paymentDone = true
            }
          })
        }
        this.getCampaign();
      })
    this.addDonationForm();
    this.getDonationSetting()
    this.locationService.getPaymentGateways(this.loc_id, '').subscribe(result => {
      let gw = result.data.filter(gw => gw.status === 'Active')[0]
      // if (gw.length >= 1) {
      if (gw.gateway_name === 'Stripe') {
        this.paymentGateway = 'Stripe'
      } else if (gw.gateway_name === 'PayPal') {
        this.paymentGateway = 'PayPal'
      } else {
        this.paymentGateway = 'Square'
      }
    })

  }

  getDonationSetting() {
    this.loading = true;
    this.donationService.GetDonationsSetting(this.loc_id).subscribe(res => {
      this.donationSettingDate = res.data
      // console.log(this.donationSettingDate[0].background_color)
      this.setCurrentStyles()
      this.loading = false
    }, err => {
      console.log(err)
      this.loading = false;
    })

  }

  setCurrentStyles() {
    // CSS styles: set per current state of component properties
    this.currentStyles = {
      'background-color': this.donationSettingDate[0] ? '#' + this.donationSettingDate[0].background_color : '',
      "input-label": this.donationSettingDate[0] ? '#' + this.donationSettingDate[0].font_color : '',
      "font_family": this.donationSettingDate[0] ? '#' + this.donationSettingDate[0].font_family : '',
    };
    this.inputStyles = {
      "color": this.donationSettingDate[0] ? '#' + this.donationSettingDate[0].font_color : '',
    };
    this.buttonStyles = {
      "background-color": this.donationSettingDate[0] ? '#' + this.donationSettingDate[0].button_color : '',
      "font_family": this.donationSettingDate[0] ? '#' + this.donationSettingDate[0].font_family : '',
    }
  }


  isActive(startTime: string) {
    const today = moment(new Date()).format('YYYY-MM-DD')
    const date = moment(new Date(startTime)).format('YYYY-MM-DD')
    return new Date(today) <= new Date(date)
  }
  getCampaign() {
    this.donationService.getCampaigndrop(this.loc_id).subscribe(res => {
      this.campaignslist = res.data.campaigns.map(ele => {
        return {
          ...ele,
          name: ele.name,
          goal: ele.goal,
          startDate: ele.startDate,
          endDate: ele.endDate,
          _id: ele.id,
          status: ele.status == undefined ? (this.isActive(ele.endDate) ? 'active' : 'inactive') : ele.status
        }
      })
        .filter(e => e.status == 'active')
    }, err => {
      console.log(err)
    })
  }

  getPaymentDesc() {
    let paydesc = `${this.getCampaignName()} Donation`
    return paydesc
  }


  addDonationForm() {
    this.donationForm = new FormGroup({
      d_campaign: new FormControl(null, [Validators.required]),
      d_first_name: new FormControl(null, [Validators.required]),
      d_last_name: new FormControl(null, [Validators.required]),
      d_user_email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      d_tel: new FormControl('', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)),
      d_address: new FormControl(null),
      d_organization: new FormControl(''),
      d_amount: new FormControl(20
        , [Validators.required]),
    });
  }

  onChange(mrChange: MatRadioChange) {
    let type = this.donationForm.value.d_amount;
    // console.log(type)
    if (type == 'Other') {
      this.showOther = true;
      this.donationForm.controls['d_amount'].setValue(null)
    } else {
      this.showOther = false
    }
  }

  cancel() {
    this.addDonationForm();
    this.paymentDone = false
  }

  onPaymentResponse(paypalPaymentResult) {
    if (paypalPaymentResult.status) {
      this.loading = false
      this.showPayment = false
      this.donationForm.reset()
      this.addDonationForm()
    }
  }

  getListItems() {
    return {
      purchaseItems: [{
        name: this.getName(),
        description: `${this.getCampaignName()} Donation`,
        amount: this.getAmount(),
        quantity: 1,
        total: this.getAmount(),
        productID: {
          label: this.getName()
        },
        currency: "USD",
        key: 'campaignId',
        donationData: this.donationForm.value
      }]
    }
  }

  getSquareItems() {
    return {
      payment_desc: `${this.getCampaignName()} Donation`,
      purchaseItems: [{
        name: this.getName(),
        description: `${this.getCampaignName()} Donation`,
        price: this.getAmount(),
        quantity: 1,
        total: this.getAmount(),
        currency: "USD",
        key: 'campaignId',
        donationData: this.donationForm.value
      }]
    }
  }

  submit() {
    this.executeRecaptcha('donationpage')
  }
  executeRecaptcha(action: string) {
    let sKC = sessionStorage.getItem('skc')
    grecaptcha.ready(() => {
      grecaptcha.execute(sKC, { action: action }).then((token: string) => {
        this.recaptchaToken = token;
        let recaptchaTok = {
          token: token
        }
        this.reCaptchaService.reCaptchaVerification(recaptchaTok).subscribe(res => {
          if (res.statusCode == 200) {
            console.log('reCAPTCHA verification successful', res);
            this.showPayment = true
          } else {
            console.log('reCAPTCHA verification failed', res);
            this.toastr.error('reCAPTCHA verification failed')
          }
        }, err => {
          console.log(err)
          this.toastr.error(err.error.data[0], 'Error in verifying reCAPTCHA:')
        })
      });
    });
  }

  getName() {
    return this.donationForm.controls['d_first_name'].value + ' ' + this.donationForm.controls['d_last_name'].value
  }
  getCampaignId() {
    return this.donationForm.controls['d_campaign'].value
  }
  getAmount() {
    return this.donationForm.controls['d_amount'].value
  }
  getLocId() {
    return this.loc_id
  }

  getCampaignName() {
    return this.campaignslist.filter(e => e._id == this.donationForm.controls['d_campaign'].value)[0].name
  }

}
