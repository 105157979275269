import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClient) { }

  getActivityLogs(page, size, search, loc_id, payload, sortBy, sortOrder) {
    let url = environment.SERVER_BASEURL + `activitylogs?page=${page}&loc_id=${loc_id}&limit=${size}&sortBy=${sortBy}&sortOrder=${sortOrder}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.post<any>(url, payload);
  }

  getEmailLogs(page, limit, user_id, search, loc_id, filter, sort, sortorder, tp_id) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/email-logs?page=${page}&limit=${limit}&sort=${sort}&sortOrder=${sortorder}`

    if (search) {
      url = url + `&mode=${search}`
    }
    if (filter && !search) {
      url = url + `&mode=${filter}`
    } if (tp_id) {
      url = url + `&tp_id=${tp_id}`
    }
    return this.http.get<any>(url);
  }

}
