import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  logout() {
    // sessionStorage.removeItem('issacUser')
    localStorage.removeItem('issacUser')
  }
  private currentUserSubject: BehaviorSubject<any>;



  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  public get currentUserValue() {
    return JSON.parse(localStorage.getItem('issacUser'));
    // if (JSON.parse(sessionStorage.getItem('issacUser'))) {
    //   return JSON.parse(sessionStorage.getItem('issacUser'))
    // } else {
    //   return JSON.parse(localStorage.getItem('issacUser'));
    // }
  }

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('issacUser')));
  }

  login(payload) {
    let headers = new HttpHeaders({
      username: payload.username,
      password: payload.password
    })
    let result = this.http.get<any>(environment.SERVER_BASEURL + 'login' + `?x=${payload.x}&y=${payload.y}&ip=${payload.ipAddress}`, {
      headers
    });
    return result
  }

  getIp() {
    return this.http.get<any>('https://icanhazip.com')
  }

  public updatUserData(data) {
    localStorage.setItem('issacUser', JSON.stringify(data))
    // if (JSON.parse(sessionStorage.getItem('issacUser'))) {
    //   sessionStorage.setItem('issacUser', JSON.stringify(data))
    // } else {
    //   localStorage.setItem('issacUser', JSON.stringify(data))
    // }
  }

  resetPassword(resetMail) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'passwd', resetMail);
  }

}
